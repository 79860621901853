import "./MiddleSectionHeader.css";

export default function MiddleSectionHeader() {
    return (
        <h1 className="landingpage_middleSectionHeader">
            Crie e personalize portfólios com o melhor da sua arte.<br />
            <br />
            Encontre artistas para seu evento.
        </h1>
    )
}