// LandingPage.js
import React from 'react';
import HeaderSection from './Sections/HeaderSection/HeaderSection';
import "./LandingPage.css";
import MiddleSection from './Sections/MiddleSection/MiddleSection';
import LastSection from './Sections/LastSection/LastSection';

function LandingPage() {
  return (
    <div className='landingpage_page'>
      <HeaderSection />
      <MiddleSection />
      <LastSection />
    </div>
  );
}

export default LandingPage;