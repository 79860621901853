// LogoSection.js
import React from 'react';
import VStack from '../../../components/VStack/VStack';
import LogoName from './LogoName/LogoName';
import AppStoreV1 from './AppStoreV1/AppStoreV1';
import InstaCTA from './InstaCTA/InstaCTA';

function LogoSection() {
  return (
    <VStack spacing="1rem" align="center" justify="center">
      <LogoName />
      <AppStoreV1 />
      <InstaCTA />
    </VStack>
  );
}

export default LogoSection;