import LogoNameSlogan from "./LogoNameSlogan/LogoNameSlogan";
import VStack from "../../components/VStack/VStack";
import AppStoreV1 from "../HeaderSection/LogoSection/AppStoreV1/AppStoreV1";

export default function LastSection() {
    return (
        <div className="landingpage_section" id='landingpage_section-3'>
            <VStack align="center" >
                <LogoNameSlogan />
                < AppStoreV1 />
            </VStack>
        </div>
    );
}