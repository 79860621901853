// HeaderSection.js
import React from 'react';
import Header from './Header/Header';
import ClippedPhoneImage from './ClippedPhoneImage/ClippedPhoneImage';
import LogoSection from './LogoSection/LogoSection';
import HStack from '../../components/HStack/HStack';

function HeaderSection() {
  return (
    <div className="landingpage_section" id='landingpage_section-1'>
      <Header />
      <HStack spacing="2rem" align="flex-end">
        <ClippedPhoneImage />
        <LogoSection />
      </HStack>
    </div>
  );
}

export default HeaderSection;