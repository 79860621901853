// HStack.js
import React from 'react';

function HStack({ children, spacing = '1rem', align = 'center', justify = 'space-between' }) {
  const style = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: align,
    justifyContent: justify,
    gap: spacing,
    height: "100%",
    width: "100%"
  };

  return <div style={style}>{children}</div>;
}

export default HStack;