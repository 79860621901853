// VStack.js
import React from 'react';

function VStack({ children, spacing = '1rem', align = 'flex-start', justify = 'flex-start', height = 'auto' }) {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: align,   // Aligns children along the cross-axis (horizontally in a column)
    justifyContent: justify,  // Aligns children along the main axis (vertically in a column)
    gap: spacing,        // Controls the spacing between children
    height: height
  };

  return <div style={style}>{children}</div>;
}

export default VStack;