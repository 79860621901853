import "./MiddleSection.css";

import VStack from "../../components/VStack/VStack";
import CroppedPhones from "./CroppedPhones/CroppedPhones";
import MiddleSectionHeader from "./MiddleSectionHeader/MiddleSectionHeader";

export default function MiddleSection() {
    return (
        <div className="landingpage_section" id='landingpage_section-2'>
            <div style={{
                height: "100%",
                width: "100%",
                paddingTop: window.innerWidth <= 768 ? "20%" : "7%"
            }}>
                <VStack spacing="1rem" align="center" justify="space-between" height="100%" >
                    <MiddleSectionHeader />
                    <CroppedPhones />
                </VStack>
            </div>
        </div>
    )
}